//import store from '@/state/store'
//import store from '../views/pages/np_pages/store.js'

export default [{
    path: '/login',
    name: 'login',
    component: () =>
        import ('../views/np_pages/new-login'),
    // meta: {
    //     guest: true,
    // }
},
// {
//   path: '/register',
//   name: 'register',
//   component: () => import('../views/pages/account/register'),
//   meta: {
//     beforeResolve(routeTo, routeFrom, next) {
//       // If the user is already logged in
//       if (store.getters['auth/loggedIn']) {
//         // Redirect to the home page instead
//         next({
//           name: 'home'
//         })
//       } else {
//         // Continue to the login page
//         next()
//       }
//     },
//   },
// },
{
    path: '/reset-password',
    name: 'resetpassword',
    component: () =>
        import ('../views/np_pages/resetpassword'),
    // if no set meta this page will be can access anytime
    // meta: {
    //     guest: true
    // }
},
{
    path: '/reset/:id/merchant-reset-password',
    name: 'merchant reset password',
    component: () =>
        import ('../views/np_pages/newpassword'),
    meta: {
        guest: true
    }
},
{
    path: '/authlogin',
    name: 'newpages authentification',
    component: () =>
        import ('../views/np_pages/authlogin'),
},
{
    path: '/old-nms/authlogin',
    name: 'Switch Old NMS',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/switch_old_nms'),
},
{
    path: '/rfq',
    name: 'newpages rfq system redirect',
    meta: {
        authRequired: true,
    },
    beforeEnter() {
        window.open("/rfq-system", "_blank");
    }
},
{
    path: '/rfq-system',
    name: 'newpages rfq system',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/rfq-system'),
},
{
    path: '/otp',
    name: '2-Step_Verification',
    component: () =>
        import ('../views/np_pages/otp'),
    meta: {
        //guest: true
    }
},
{
    path: '/logout',
    name: 'logout',
    meta: {
        authRequired: true,

    },
    component: () =>
        import ('../views/np_pages/logout'),
},
{
    path: '/',
    name: 'home',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/home')
},
{
    path: '/dashboard',
    name: 'dashboard',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/index_ver2')
},
{
    path: '/dashboard_v2',
    name: 'dashboard_v2',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/index_ver2')
},
{
    path: '/announcement/details/:id',
    name: 'Announcement Details',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/announcement_details')
},
{
    path: '/newpages-add-on',
    name: 'NEWPAGES Add-on System',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/addon_system_home')
},
{
    path: '/add-on/purchased-records',
    name: 'Add-on - Purchased Records',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/dashboard/addon_system/purchased_records')
},
{
    path: '/add-on/purchased-record/:id/detail',
    name: 'Add-on - Purchased Record Detail',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/dashboard/addon_system/purchased_record_detail')
},
{
    path: '/add-on/email-account',
    name: 'Add-On Email Account',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/dashboard/addon_system/email_account')
},

{
    path: '/add-on/payment-gateway',
    name: 'Add-On Payment Gateway',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/dashboard/addon_system/payment_gateway')
},
{
    path: '/account-information',
    name: 'account_information',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/account_information')
},

{
    path: '/change-password',
    name: 'change_password',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/account/change_password')
},

{
    path: '/two-factor-authentication',
    name: 'Two-Factor Authentication',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/two_factor_authentication')
},
{
    path: '/user-roles',
    name: 'User Roles',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/user_roles')
},
{
    path: '/user/:id/user-log/actions',
    name: 'User Logs',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/user_logs')
},
{
    path: '/user/:id/user-log',
    name: 'User Logs Monthly View',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/user_logs_month_view')
},
{
    path: '/my-np-voucher',
    name: 'My NP Voucher',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/np_voucher')
},
{
    path: '/affiliate-marketing/report',
    name: 'Affiliate Makerting - Report',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/affiliate_marketing/affiliate_report')
},
{
    path: '/affiliate-marketing/report/click-from-sources',
    name: 'Affiliate Makerting - Click From Sources',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/affiliate_marketing/affiliate_report_click_sources')
},
{
    path: '/affiliate-marketing/report/event/:id/click-from-sources',
    name: 'Affiliate Makerting - Click From Sources with Event',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/affiliate_marketing/affiliate_report_click_sources')
},
{
    path: '/affiliate-marketing/report/registered-lists',
    name: 'Affiliate Makerting - Registered List',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/affiliate_marketing/affiliate_report_registered_list')
},
{
    path: '/affiliate-marketing/report/event/:id/registered-lists',
    name: 'Affiliate Makerting - Registered List with Event',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/affiliate_marketing/affiliate_report_registered_list')
},
{
    path: '/active-sessions',
    name: 'Active Session',
    meta: {
        authRequired: true,
        is_admin: true
    },
    component: () =>
        import ('../views/np_pages/account/active_session')
},
{
    path: '/scan-and-secure-login',
    name: 'Scan and Secure Login',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/account/scan_login')
},
// {
//     path: '/user-role/edit/:id',
//     name: 'Edit User Role',
//     meta: {
//         authRequired: true,
//         is_admin: true
//     },
//     component: () =>
//         import ('../views/np_pages/account/add_user_role')
// },
{
    path: '/onesync/instagram-sync',
    name: 'Import Instagram Photos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/import_instagram_photos')
},

{
    path: '/instagram-redirect',
    name: 'Instagram Redirect',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/instagram_redirect')
},
{
    path: '/support/remote-support',
    name: 'Remote Support',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/remote-support')
},
{
    path: '/support/update-form',
    name: 'Update Form',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/pdf-frame')
},
{
    path: '/support/insert-script-request-form',
    name: 'Insert Script Request Form',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/pdf-frame')
},
{
    path: '/support/mx-setting-form',
    name: 'MX Setting Form',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/pdf-frame')
},

{
    path: '/support/delete-email-request-form',
    name: 'Delete Email Form',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/pdf-frame')
},
{
    path: '/support/email-settings',
    name: 'Email Settings',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/support/pdf-frame')
},
{
    path: '/contact',
    name: 'Contact',
    beforeEnter() {
        window.open("https://www.newpages.com.my/v2/en/contact-us.html", "_blank");
    }
},
{
    path: '/help-&-support',
    name: 'NEWPAGES Help',
    beforeEnter() {
        window.open("https://edu.newpages.com.my", "_blank");
    }
},
{
    path: '/app/app-management',
    name: 'App Management',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/app_management')
},
{
    path: '/app/app-management/slider/add',
    name: 'Add Slider',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/app_slider')
},
{
    path: '/app/app-management/slider/edit/:id',
    name: 'Edit Slider',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/app_slider')
},
{
    path: '/app/push-message',
    name: 'Push Message',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/push_message')
},
{
    path: '/app/shake-campaigns',
    name: 'Shake Campaigns',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/list')
},

{
    path: '/app/shake-campaigns/sms-credit',
    name: 'Shake Campaigns - SMS Credit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/sms_credit')
},

{
    path: '/app/shake-campaigns/shake-users',
    name: 'Shake Campaigns - Shake User',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/shake_users')
},

{
    path: '/app/shake-campaigns/shake-users/:id/detail',
    name: 'Shake Campaigns - Shake User Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/shake_user_detail')
},

{
    path: '/app/shake-campaigns/add',
    name: 'Shake Campaigns - Add New Campaign',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/add_edit_campaign')
},

{
    path: '/app/shake-campaigns/edit/:id/detail',
    name: 'Shake Campaigns - Edit Campaign Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/app/shake_campaigns/add_edit_campaign')
},


{
    path: '/report/newpages-enquiry',
    name: 'NEWPAGES Enquiry',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/enquiries/newpages_enquiry')
},

{
    path: '/report/website-enquiry',
    name: 'Website Enquiry',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/enquiries/website_enquiry')
},

{
    path: '/report/website-enquiry/detail/:id',
    name: 'Website Enquiry Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/enquiries/enquiry_detail')
},

{
    path: '/report/newpages-enquiry/detail/:id',
    name: 'NEWPAGES Enquiry Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/enquiries/enquiry_detail')
},
{
    path: '/report/website-landing-pages',
    name: 'Website Landing Pages',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/landing_pages/website_landing_pages')
},

{
    path: '/report/newpages-landing-pages',
    name: 'NEWPAGES Landing Pages',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/landing_pages/newpages_landing_pages')
},

// {
//     path: '/demo',
//     name: 'demo',
//     meta: {
//         authRequired: true,
//         //is_admin : true
//     },
//     component: () =>
//         import ('../views/pages/dashboard/demo')
// },
// {
//     path: '/calendar',
//     name: 'calendar',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/calendar/index')
// },
// {
//     path: '/chat',
//     name: 'chat',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/chat/index')
// },

// {
//     path: '/ecommerce/products',
//     name: 'Products',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/products')
// },
// {
//     path: '/ecommerce/product-detail/:id',
//     name: 'Product Detail',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/product-detail')
// },
// {
//     path: '/ecommerce/orders',
//     name: 'Orders',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/orders')
// },
// {
//     path: '/ecommerce/customers',
//     name: 'Customers',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/customers')
// },
// {
//     path: '/ecommerce/cart',
//     name: 'Cart',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/cart')
// },
// {
//     path: '/ecommerce/checkout',
//     name: 'Checkout',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/checkout')
// },
// {
//     path: '/ecommerce/shops',
//     name: 'Shops',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/shops')
// },
// {
//     path: '/ecommerce/add-product',
//     name: 'Add Product',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/ecommerce/add-product')
// },
// {
//     path: '/email/inbox',
//     name: 'Inbox',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/email/inbox')
// },
// {
//     path: '/email/reademail/:id',
//     name: 'Read Email',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/email/reademail')
// },
// {
//     path: '/invoices/detail',
//     name: 'Invoice Detail',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/invoices/detail')
// },
// {
//     path: '/invoices/list',
//     name: 'Invoice List',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/invoices/list')
// },
// {
//     path: '/contacts/grid',
//     name: 'User Grid',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/contacts/grid')
// },
// {
//     path: '/contacts/list',
//     name: 'User List',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/contacts/list')
// },
// {
//     path: '/contacts/profile',
//     name: 'Profile',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/contacts/profile')
// },
// {
//     path: '/utility/starter',
//     name: 'Starter-page',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('../views/pages/utility/starter')
// },
// {
//     path: '/utility/maintenance',
//     name: 'maintenance',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/maintenance')
// },
// {
//     path: '/utility/comingsoon',
//     name: 'comingsoon',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/comingsoon')
// },
// {
//     path: '/utility/timeline',
//     name: 'timeline',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/timeline')
// },
// {
//     path: '/utility/faqs',
//     name: 'faqs',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/faqs')
// },
// {
//     path: '/utility/pricing',
//     name: 'pricing',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/pricing')
// },
// {
//   path: '/utility/404',
//   name: 'error-404',
//   meta: {
//     authRequired: true,
//   },
//   component: () => import('../views/pages/utility/404')
// },
// {
//     path: '/utility/500',
//     name: 'error-500',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/utility/500')
// },
// {
//     path: '/form/elements',
//     name: 'elements',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/elements')
// },
// {
//     path: '/form/validation',
//     name: 'validation',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/validation')
// },
// {
//     path: '/form/advanced',
//     name: 'form-advanced',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/advanced')
// },
// {
//     path: '/form/editor',
//     name: 'editor',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/editor')
// },
// {
//     path: '/form/upload',
//     name: 'upload',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/upload')
// },
// {
//     path: '/form/repeater',
//     name: 'repeater',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/repeater')
// },
// {
//     path: '/form/wizard',
//     name: 'wizard',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/wizard')
// },
// {
//     path: '/form/mask',
//     name: 'mask',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/form/mask')
// },
// {
//     path: '/tables/basic',
//     name: 'basic-table',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/tables/basic')
// },
// {
//     path: '/tables/advanced',
//     name: 'advanced',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/tables/advanced')
// },
// {
//     path: '/charts/apex',
//     name: 'apex',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/charts/apex/index')
// },
// {
//     path: '/charts/chartist',
//     name: 'chartist',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/charts/chartist/index')
// },
// {
//     path: '/charts/chartjs',
//     name: 'chartjs',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/charts/chartjs/index')
// },
// {
//     path: '/charts/echart',
//     name: 'echart',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/charts/echart/index')
// },
{
    path: '/icons/unicons',
    name: 'unicons',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/pages/icons/unicons')
},
{
    path: '/icons/boxicons',
    name: 'boxicons',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/pages/icons/boxicons')
},
{
    path: '/icons/materialdesign',
    name: 'materialdesign',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/pages/icons/materialdesign')
},
{
    path: '/icons/dripicons',
    name: 'dripicons',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/pages/icons/dripicons')
},
{
    path: '/icons/fontawesome',
    name: 'fontawesome',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/pages/icons/fontawesome')
},
// {
//     path: '/maps/google',
//     name: 'google',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/maps/google')
// },
// {
//     path: '/maps/leaflet',
//     name: 'leaflet',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/maps/leaflet')
// },
// {
//     path: '/ui/alerts',
//     name: 'alerts',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/alerts')
// },
// {
//     path: '/ui/buttons',
//     name: 'buttons',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/buttons')
// },
// {
//     path: '/ui/cards',
//     name: 'cards',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/cards')
// }, {
//     path: '/ui/carousel',
//     name: 'carousel',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/carousel')
// }, {
//     path: '/ui/dropdown',
//     name: 'dropdown',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/dropdown')
// }, {
//     path: '/ui/grid',
//     name: 'grid',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/grid')
// }, {
//     path: '/ui/images',
//     name: 'images',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/images')
// }, {
//     path: '/ui/lightbox',
//     name: 'lightbox',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/lightbox')
// },
// {
//     path: '/ui/modals',
//     name: 'modals',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/modals')
// },
// {
//     path: '/ui/rangeslider',
//     name: 'rangeslider',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/rangeslider')
// },
// {
//     path: '/ui/progressbar',
//     name: 'progressbar',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/progressbar')
// },
// {
//     path: '/ui/sweet-alert',
//     name: 'sweet-alert',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/sweet-alert')
// },

// {
//     path: '/ui/tabs-accordions',
//     name: 'tabs-accordions',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/tabs-accordions')
// },
// {
//     path: '/ui/typography',
//     name: 'typography',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/typography')
// },

// {
//     path: '/ui/video',
//     name: 'video',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/video')
// },
// {
//     path: '/ui/general',
//     name: 'general',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/general')
// },
// {
//     path: '/ui/colors',
//     name: 'colors',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/colors')
// },
// {
//     path: '/ui/rating',
//     name: 'rating',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/ui/rating')
// },
// {
//     path: '/auth/login-1',
//     name: 'login-1',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/auth/login-1')
// },
// {
//     path: '/auth/register-1',
//     name: 'register-1',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/auth/register-1')
// }, {
//     path: '/auth/lock-screen',
//     name: 'lock-screen',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/auth/lock-screen')
// }, {
//     path: '/auth/recoverpwd',
//     name: 'recoverpwd',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/pages/auth/recoverpwd')

// },
{
    path: '/onesync/facebook-sync',
    name: 'Facebook Sync',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/facebook_sync')
},
{
    path: '/facebook-connect',
    name: 'Facebook Connect',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/facebook_redirect')
},
{
    path: '/onesync/mychatbot',
    name: 'MyChatbot',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/mychatbot/index')
},
{
    path: '/onesync/mychatbot/broadcast-message/:page_id',
    name: 'Broadcast Message',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/onesync/mychatbot/broadcast_message')
},
{
    path: '/mysales/invoice',
    name: 'Invoice',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/invoice/list')
},

{
    path: '/mysales/whatsapp-orders',
    name: 'WhatsApp Orders',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/whatsapp_order/list')
},

{
    path: '/whatsapp-cart-chat',
    name: 'WhatsApp Cart & Chat',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/whatsapp_cart_chat/list')
},

/* -------------------------------------------------------------------------- */
/*                                   eric page                                  */
/* -------------------------------------------------------------------------- */
{
    path: '/mysales/dashboard',
    name: 'order_dashboard',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_dashboard/index')
},
{
    path: '/mysales/notification',
    name: 'Order Notification',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_dashboard/notification')
},
{
    path: '/mysales/dashboard/products',
    name: 'Dashboard Products',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_dashboard/products_list')
},
{
    path: '/mysales/order',
    name: 'My Order List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_list/list')
},
{
    path: '/mysales/order/:oid',
    name: 'Order Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_list/detail')
},
{
    path: '/mysales/quotation',
    name: 'My Quotation List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/quotation_list/list')
},
{
    path: '/mysales/quotation/:oid',
    name: 'Quotation Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_list/detail')
},
{
    path: '/mysales/delivery',
    name: 'Delivery List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/delivery/list')
},
{
    path: '/mysales/packaging',
    name: 'Packaging List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/packaging/list')
},
{
    path: '/mysales/packaging-bill',
    name: 'Packaging Bill',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/packaging/packaging_page')
},
{
    path: '/mysales/marketing-tools/voucher',
    name: 'Marketing Voucher',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/voucher/list')
},
{
    path: '/mysales/marketing-tools/voucher/:vid',
    name: 'Voucher Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/voucher/detail')
},
{
    path: '/mysales/marketing-tools/discount-campaigns',
    name: 'Marketing Discount Campaigns',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/discount_campaigns/list')
},
{
    path: '/mysales/marketing-tools/discount-campaigns/new',
    name: 'Marketing Create Discount Campaigns',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/discount_campaigns/create_detail')
},
{
    path: '/mysales/marketing-tools/discount-campaigns/:dcid',
    name: 'Marketing Discount Campaigns Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/discount_campaigns/detail')
},
{
    path: '/mysales/marketing-tools/add-on-deals',
    name: 'Marketing Add On Deals',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/add_on_deals/list')
},
{
    path: '/mysales/marketing-tools/add-on-deals/new',
    name: 'Marketing Create Add On Deals',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/add_on_deals/create_detail')
},
{
    path: '/mysales/marketing-tools/add-on-deals/:did',
    name: 'Marketing Add On Deals Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/add_on_deals/detail')
},
{
    path: '/mysales/marketing-tools/products-sold-and-customer-report',
    name: 'Marketing Know Your Customer',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/know_your_customers/list')
},
{
    path: '/mysales/marketing-tools/broadcast-management',
    name: 'Marketing Broadcast Management',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/broadcast_management/list')
},
{
    path: '/mysales/marketing-tools/broadcast-management/:bid',
    name: 'Marketing Broadcast Management Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/broadcast_management/detail')
},
{
    path: '/mysales/setting',
    name: 'My Sales Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/index')
},
{
    path: '/mysales/shipping-setting',
    name: 'Shipping Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_setting/index')
},
{
    path: '/mysales/shipping-setting/:zid',
    name: 'Zone',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_setting/detail')
},
{
    path: '/mysales/shipping-setting/option/:sid',
    name: 'Shipping Option',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_setting/shipping_detail')
},
{
    path: '/mysales/member',
    name: 'Member',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/member/list')
},
{
    path: '/mysales/import-members',
    name: 'Import Members',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/member/import')
},
{
    path: '/mysales/member/:uid',
    name: 'Member Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/member/detail')
},
{
    path: '/report/sale&order/order-report',
    name: 'Sale And Order Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/order_report/index')
},
{
    path: '/report/sale&order/products-performance',
    name: 'Products Performance',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/products_performance/index')
},
{
    path: '/marketplace-sync',
    name: 'Marketplace Sync',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/index')
},
{
    path: '/marketplace-sync/dashboard',
    name: 'Marketplace Sync Dashboard',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/dashboard')
},
{
    path: '/marketplace-sync/products-list/:sid',
    name: 'Marketplace Sync Products List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/products_list')
},
{
    path: '/marketplace-sync/boost/:sid',
    name: 'Marketplace Sync Boost Products',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/boost_list')
},
{
    path: '/marketplace-sync/orders',
    name: 'Marketplace Sync Orders List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/orders_list')
},
{
    path: '/marketplace-sync/orders/:oid',
    name: 'Marketplace Sync Orders Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/marketplace_sync/order_detail')
},
/* -------------------------------------------------------------------------- */
/*                                Eric Phase 2                                */
/* -------------------------------------------------------------------------- */
{
    path: '/mysales/payment-link',
    name: 'Payment Link',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/payment_link/list')
},
{
    path: '/mysales/payment-link/payment-detail/:pid',
    name: 'Payment Link Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/payment_link/detail')
},
{
    path: '/mysales/purchase-link',
    name: 'Purchase Link',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/purchase_link/list')
},
{
    path: '/mysales/purchase-link/purchase-detail/:pid',
    name: 'Purchase Link Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/purchase_link/detail')
},
{
    path: '/mysales/marketing-tools/birthday-campaigns',
    name: 'Birthday Campaign',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/birthday_campaigns/index.vue')
},
{
    path: '/mysales/live-commerce/setting',
    name: 'Live Commerce Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/live_commerce/setting/index.vue')
},
{
    path: '/mysales/delivery/shipping-list',
    name: 'Shipping List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/delivery/shipping_page.vue')
},
{
    path: '/mysales/live-commerce/product-set',
    name: 'Live Commerce Product Set',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/live_commerce/product/list.vue')
},
{
    path: '/mysales/live-commerce/product-set/:sid',
    name: 'Live Commerce Product Set Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/live_commerce/product/detail.vue')
},
{
    path: '/mysales/live-commerce/live-streaming',
    name: 'Live Commerce Live Streaming',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/live_commerce/live/list.vue')
},
{
    path: '/mysales/live-commerce/fb-member',
    name: 'Live Commerce FB Member',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/live_commerce/member/list.vue')
},
{
    path: '/report/sale&order/order-report-page',
    name: 'Order Report Page',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/order_report/order_page.vue')
},
{
    path: '/report/sale&order/order-report-page/pdf',
    name: 'Order Report PDF',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/order_report/order_pdf.vue')
},
{
    path: '/mysales/loyalty-and-reward/member-reward/',
    name: 'Reward Points',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward/index.vue')
},
{
    path: '/mysales/loyalty-and-reward/member-tier/',
    name: 'Reward Tier',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward/tier_index.vue')
},
{
    path: '/mysales/loyalty-and-reward/member-reward/tier/:tid',
    name: 'Member Tier Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward/tier_detail.vue')
},
{
    path: '/mysales/loyalty-and-reward/history',
    name: 'Reward History',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward/history.vue')
},
{
    path: '/mysales/loyalty-and-reward/setting',
    name: 'Loyalty And Reward Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward_setting/index.vue')
},
{
    path: '/mysales/loyalty-and-reward/member-reward/setting/:rid',
    name: 'Reward Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward/rules_detail.vue')
},
{
    path: '/mysales/loyalty-and-reward/member-reward-station/',
    name: 'Reward Station',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward_station/index.vue')
},
{
    path: '/mysales/loyalty-and-reward/reward-station/item/:id',
    name: 'Reward Station Item Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward_station/item_detail.vue')
},
{
    path: '/mysales/loyalty-and-reward/reward-station/pending-approval',
    name: 'Reward Station Pending List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/loyalty_and_reward/member_reward_station/pending_table.vue')
},
{
    path: '/manage/ratings-and-reviews',
    name: 'Ratings And Reviews',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/ratings_and_reviews/list.vue')
},
{
    path: '/mysales/trackingmy',
    name: 'Parcel Tracking',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/tracking_parcel/list.vue')
},
{
    path: '/mysales/trackingmy/guideline',
    name: 'Tracking Guideline',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/tracking_parcel/guideline.vue')
},
{
    path: '/report/monthly-report-view',
    name: 'Monthly Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/monthly_report/index.vue')
},
{
    path: '/mysales/pickup-setting',
    name: 'Pick Up Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_setting/self_pick_up')
},
{
    path: '/mysales/mass-manage/order',
    name: 'Order Mass Manage',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_list/mass_manage/index')
},
{
    path: '/sme/setup',
    name: 'SME Setup',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/sme/index')
},
// {
//     path: '/sme/list',
//     name: 'SME List View',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/sme/sme_list')
// },
{
    path: '/sme/sme_list',
    name: 'SME List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/sme/sme_list')
},
{
    path: '/sme/certificate',
    name: 'SME Certificate',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/sme/certificate')
},
{
    path: '/sme/privilege',
    name: 'SME Privilege',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/sme/privilege')
},
{
    path: '/plugins/',
    name: 'Plugins Main Page',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/plugins/plugins_index')
},
{
    path: '/plugins/detail/:pid',
    name: 'Plugins Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/plugins/plugins_detail')
},
{
    path: '/purchased-plugins',
    name: 'Purchased Plugins',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/plugins/purchased_plugins_index')
},
{
    path: '/plugins/terms-and-agreement',
    name: 'Plugins Terms',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/plugins/plugin_terms')
},
{
    path: '/authorize',
    name: 'authorize login',
    component: () =>
        import ('../views/np_pages/authorize-from-old-login'),
    meta: {
        guest: true,
    }
},
{
    path: '/manage/products/mass-update',
    name: 'Mass Update',
    component: () =>
        import ('../views/np_pages/manage/products/mass_update'),
    meta: {
        authRequired: true,
    }
},
{
    path: '/report/traffic-guarantee-report',
    name: 'Traffic Guarantee Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/traffic_guarantee_report')
},
{
    path: '/chatgpt/history',
    name: 'ChatGPT History',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/components/chat_gpt_history')
}, {
    path: '/profile/branches',
    name: 'All Branches',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/branch/all')
}, {
    path: '/manage/branch/business-hour',
    name: 'Branch Business Hour',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/business-hour')
},
// {
//     path: '/manage/branch/gallery-group',
//     name: 'Branch Gallery Group',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/manage/other/group')
// },
{
    path: '/manage/product-specification',
    name: 'Product Specification',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/manage_spec')
},
{
    path: '/manage/careers/recruitment-list',
    name: 'Recruitment List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/careers/recruitment_list')
},
{
    path: '/business-tag',
    name: 'Business Tag',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/business-tag/card_index')
},
{
    path: '/business-tag/add-card-status',
    name: 'Business Tag Add Card Status',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/business-tag/add_card_status')
},
{
    path: '/business-tag/report/:id',
    name: 'Business Tag Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/business-tag/report')
},
{
    path: '/tools/web-push-notification',
    name: 'Web Push Notification',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/web_push_notification')
},
{
    path: '/sme/sme-company-details/:selected_company_id',
    name: 'SME Company Details',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/sme/sme_list_details')
},
{
    path: '/warranty-system',
    name: 'Warranty',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/warranty-page')
},
/* -------------------------------------------------------------------------- */
/*                                Jey Phase 2  in Mysales                     */
/* -------------------------------------------------------------------------- */
{
    path: '/mysales/ecommerce-mail-setting',
    name: 'Ecommerce Mail Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/ecommerce_mail_setting')
},
{
    path: '/mysales/popup-and-social-proof',
    name: 'Popup & Social Proof',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/popup_social_proof')
},
{
    path: '/manage/content-generator',
    name: 'Content Generator',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/content_generator')
},
{
    path: '/manage/content-generator/faq',
    name: 'Frequently Asked Question (FAQ)',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/faq')
},
{
    path: '/manage/content-generator/return-and-refund-policy',
    name: 'Return & Refund Policy',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/return_refund_policy')
},
{
    path: '/manage/content-generator/why-choose-us-content',
    name: 'Why Choose Us Content',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/order_setting/why_choose_us_content')
},
{
    path: '/website/product-specification-filter-setting',
    name: 'Product Specification Filter Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/product_specification_setting')
},
/* -------------------------------------------------------------------------- */
/*                                   you sheng page                          */
/* -------------------------------------------------------------------------- */
{
    path: '/manage/products',
    name: 'viewproducts',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/view')
},
// {
//     path: '/manage/products/add2',
//     name: 'addproducts2',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/manage/products/add2')
// },
// {
//     path: '/manage/products/edit2/:id',
//     name: 'editproduct2',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/manage/products/edit2')
// },
{
    path: '/manage/products/add',
    name: 'addproducts',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/add')
}, {
    path: '/manage/products/add-multiple',
    name: 'addmultipleproducts',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/addmultiple')
}, {
    path: '/manage/products/quick-edit',
    name: 'quickeditproducts',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/quickedit')
}, {
    path: '/manage/products/edit/:id',
    name: 'editproduct',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/edit')
}, {
    path: '/manage/products/category/',
    name: 'manageproductcategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/category')
}, {
    path: '/manage/products/tag/',
    name: 'manageproducttag',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/tag')
}, {
    path: '/manage/products/brand/',
    name: 'manageproductbrand',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/brand')
}, {
    path: '/manage/products/google-category/',
    name: 'manageproductgooglecategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/googlecategory')
}, {
    path: '/manage/services',
    name: 'viewservices',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/services/view')
},
{
    path: '/manage/services/add',
    name: 'addservices',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/services/add')
}, {
    path: '/manage/services/edit/:id',
    name: 'editservice',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/services/edit')
}, {
    path: '/manage/services/category',
    name: 'servicescategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/services/category')
}, {
    path: '/manage/services/tags',
    name: 'servicestags',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/services/manage_tag')
}, {
    path: '/manage/photos',
    name: 'viewphotos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/view')
},
{
    path: '/manage/photos/add',
    name: 'addphotos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/add')
}, {
    path: '/manage/photos/edit/:id',
    name: 'editphoto',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/edit')
}, {
    path: '/manage/photos/category',
    name: 'photoscategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/category')
}, {
    path: '/manage/photos/quickedit',
    name: 'quickeditphotos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/quickedit')
}, {
    path: '/manage/photos/add-multiple',
    name: 'addmultiplephotos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/addmultiple')
}, {
    path: '/manage/photos/add-album',
    name: 'addalbum',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/photos/addalbum')
}, {
    path: '/manage/news',
    name: 'viewnews',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/news/view')
},
{
    path: '/manage/news/add',
    name: 'addnews',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/news/add')
}, {
    path: '/manage/news/edit/:id',
    name: 'editnew',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/news/edit')
}, {
    path: '/manage/news/category',
    name: 'newscategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/news/category')
}, {
    path: '/manage/timeline',
    name: 'viewtimeline',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/timeline/view')
}, {
    path: '/manage/careers',
    name: 'viewcareers',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/careers/view')
},
{
    path: '/manage/careers/add',
    name: 'addcareer',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/careers/add')
}, {
    path: '/manage/careers/edit/:id',
    name: 'editcareer',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/careers/edit')
}, {
    path: '/manage/downloads',
    name: 'viewdownloads',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/downloads/view')
},
{
    path: '/manage/downloads/add',
    name: 'adddownload',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/downloads/add')
}, {
    path: '/manage/downloads/edit/:id',
    name: 'editdownload',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/downloads/edit')
}, {
    path: '/manage/downloads/quickedit',
    name: 'quickeditdownloads',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/downloads/quickedit')
}, {
    path: '/manage/downloads/category',
    name: 'downloadscategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/downloads/category')
}, {
    path: '/manage/youtube',
    name: 'viewyoutube',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/youtube/view')
},
{
    path: '/manage/youtube/add',
    name: 'addyoutube',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/youtube/add')
}, {
    path: '/manage/youtube/edit/:id',
    name: 'edityoutube',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/youtube/edit')
}, {
    path: '/manage/youtube/category',
    name: 'youtubecategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/youtube/category')
}, {
    path: '/manage/other',
    name: 'viewother',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/view')
},
{
    path: '/manage/other/add',
    name: 'addother',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/add')
}, {
    path: '/manage/other/edit/:id',
    name: 'editother',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/edit')
}, {
    path: '/manage/other/category',
    name: 'othercategory',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/category')
}, {
    path: '/manage/other/group',
    name: 'othergroup',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/group')
}, {
    path: '/manage/other/add-multiple',
    name: 'addmultipleother',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/addmultiple')
}, {
    path: '/manage/other/quickedit',
    name: 'quickeditother',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/other/quickedit')
},
{
    path: '/manage/pages',
    name: 'viewpages',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/pages/view')
},
{
    path: '/manage/page/add',
    name: 'addpages',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/pages/add')
}, {
    path: '/manage/page/edit/:id',
    name: 'editpages',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/pages/edit')
},
{
    path: '/manage/testimonials',
    name: 'viewTestimonial',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/testimonial/view')
},
{
    path: '/manage/testimonial/add',
    name: 'addTestimonial',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/testimonial/add')
}, {
    path: '/manage/testimonial/edit/:id',
    name: 'editTestimonial',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/testimonial/edit')
},
{
    path: '/manage/products/sync2shopee/shop/:sid/product/:id',
    name: 'Sync Product to Shopee',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/sync2Shopee')
},
{
    path: '/manage/products/sync2lazada/shop/:sid/product/:id',
    name: 'Sync Product to Lazada',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/products/sync2Lazada')
},
/* -------------------------------------------------------------------------- */
/*                                   weijie page                                  */
/* -------------------------------------------------------------------------- */
{
    path: '/profile/business-nature',
    name: 'Business Nature',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/business-nature')
},
{
    path: '/profile/classified',
    name: 'Classified',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/classified')
},
{
    path: '/profile/business-hour',
    name: 'Business Hour',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/business-hour')
},
{
    path: '/profile/contact&social-media',
    name: 'Contact&Social Media',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/contact&social-media')
},
{
    path: '/profile/homepage',
    name: 'Homepage',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/homepage')
},
{
    path: '/profile/company-tag',
    name: 'Company Tag',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/company-tag')
},
{
    path: '/profile/product-showcases',
    name: 'Product Showcases',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/product-showcase/home')
},
{
    path: '/profile/sales-landing-page',
    name: 'Sales Landing Page',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/sales-landing-data/sales-landing-page')
},
{
    path: '/profile/sales-landing-page-binding',
    name: 'Sales Landing Page Binding',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/sales-landing-data/sales-landing-binding')
},
{
    path: '/profile/:id/sales-landing-details',
    name: 'Sales Landing Page Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/sales-landing-data/sales-landing-detail')
},
{
    path: '/profile/:id/sales-landing-customization',
    name: 'Sales Landing Page Customization',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/sales-landing-data/sales-landing-customize')
},
{
    path: '/profile/:id/sales-landing-report',
    name: 'Sales Landing Page Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/profile_setting/sales-landing-data/sales-landing-report')
},
{
    path: '/booking/dashboard',
    name: 'Booking Dashboard',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/dashboard')
},
{
    path: '/booking/services',
    name: 'System Settings',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/booking_services/list')
},
{
    path: '/booking/services/:sid',
    name: 'Booking Services Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/booking_services/detail')
},
{
    path: '/booking/place-venue',
    name: 'Manage Branch',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/booking_place_venue/list')
},
{
    path: '/booking/place-venue/:bid',
    name: 'Manage Branch Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/booking_place_venue/detail')
},
{
    path: '/booking/calendar',
    name: 'Booking Calendar',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/booking/booking_calendar/index')
},

// {
//     path: '/profile/product-showcase',
//     name: 'Product Showcase',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/profile_setting/product-showcase/product-showcase-index')
// },
// {
//     path: '/profile/product-showcase/product-showcase-report/:id',
//     name: 'Product Showcase Report',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/profile_setting/product-showcase/product-showcase-report')
// },
// {
//     path: '/profile/product-showcase/product-showcase-step',
//     name: 'Product Showcase Step',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/profile_setting/product-showcase/product-showcase-step')
// },
/* -------------------------------------------------------------------------- */
/*                                   jw page                                  */
/* -------------------------------------------------------------------------- */
{
    path: '/website/banner',
    name: 'Banner',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/banner')
}, {
    path: '/website/banner/sort',
    name: 'Banner Sorting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/banner_sort')
},
{
    path: '/website/banner/edit/:id',
    name: 'Banner Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/banner_edit')
},
{
    path: '/website/customizeurl',
    name: 'Customize Url',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/customize_url')
},
{
    path: '/website/setting',
    name: 'Website Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/website_setting/website_setting')
},
{
    path: '/tools/email-marketing',
    name: 'Email Marketing',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/email_marketing')
},
{
    path: '/tools/email-marketing/edit/:id',
    name: 'Email Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/edit_mail')
},
{
    path: '/tools/contact-list',
    name: 'Contact List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/contact_list')
},
{
    path: '/tools/contact-list/edit/:id',
    name: 'Contact Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/edit_contact')
},
{
    path: '/tools/keyword-monitoring',
    name: 'Keyword Monitoring',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/keyword_monitoring_tool')
},
{
    path: '/tools/keyword-planner',
    name: 'Keyword Planner',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/keyword_planner')
},
{
    path: '/tools/bg-remover',
    name: 'Background Remover',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/bg_remover')
},

{
    path: '/tools/pdf-encryptor',
    name: 'PDF Encryptor',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/pdf_encryptor')
},

{
    path: '/mysales/lalamove',
    name: 'Lalamove',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_lalamove/lalamove_list')
},

{
    path: '/mysales/lalamove/guideline',
    name: 'Lalamove Guideline',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/shipping_lalamove/lalamove_guideline')
},

{
    path: '/mysales/marketing-tools/mix-and-match',
    name: 'Mix And Match',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/list')
},

{
    path: '/mysales/marketing-tools/mix-and-match/new',
    name: 'Marketing Create Mix And Match',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/create_detail')
},

{
    path: '/mysales/marketing-tools/mix-and-match/details/:mid',
    name: 'Marketing Mix And Match Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/detail')
},

{
    path: '/mysales/marketing-tools/mix-and-match/category',
    name: 'Marketing Mix And Match Category',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/category')
},

{
    path: '/mysales/marketing-tools/mix-and-match/category/new',
    name: 'Marketing Create Mix And Match Category',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/category_add')
},

{
    path: '/mysales/marketing-tools/mix-and-match/category/detail/:cid',
    name: 'Marketing Mix And Match Category Detail',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/my_order/marketing_tools/mix_and_match/category_detail')
},

{
    path: '/manage/google-reviews',
    name: 'Google Reviews',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/manage/google_reviews/google_review')
},

/* -------------------------------------------------------------------------- */
/*                                   CL page                                  */
/* -------------------------------------------------------------------------- */

{
    path: '/crm',
    name: 'CRM',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/crm/redirect')
},


/* -------------------------------------------------------------------------- */
/*                                   hau page                                 */
/* -------------------------------------------------------------------------- */

{
    path: '/referral/referral-list',
    name: 'Referral System',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/referral_list')
},
{
    path: '/referral/referral-info-edit/:action_id',
    name: 'Referral Info Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/referral_edit_page')
},
{
    path: '/referral/referral-group',
    name: 'Referral Group',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/referral_group')
},
{
    path: '/referral/referral-group-edit/:action_id',
    name: 'Referral Group Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/referral_group_edit')
},
{
    path: '/referral/performance',
    name: 'Performance',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/performance')
},
{
    path: '/referral/records',
    name: 'Referral Records',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/referral_records')
},
{
    path: '/referral/performance/:id',
    name: 'Performance Personal',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/performance_personal')
},
{
    path: '/referral/performance-report/:referral_id',
    name: 'Referral Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/referral/performance_report')
},
{
    path: '/report/products-view-report',
    name: 'Products View Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/product_view')
},
{
    path: '/report/ip-view-report',
    name: 'IP Views Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/ip_tracker_view')
},
{
    path: '/report/whatsapp-click-view-report',
    name: 'WhatsApp Click Views Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/whatsapp_click_view')
},
{
    path: '/report/website-search-report',
    name: 'Website Search Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/website_search_view')
},
{
    path: '/report/website-visitor-report',
    name: 'Website Visitor Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/website_visitor_view')
},
{
    path: '/report/newpages-visitor-report',
    name: 'NEWPAGES Visitor Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/newpages_visitor_view')
},
{
    path: '/report/monthly-report',
    name: 'Download Monthly Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/monthly_report_download')
},
{
    path: '/report/website-statistics',
    name: 'Website Statistics',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/website_statistics')
},
{
    path: '/report/keyword-ranking-report',
    name: 'Keyword Ranking Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/keyword_ranking_report')
},
{
    path: '/report/lead-report',
    name: 'Lead Report',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/lead_report/list')
},
{
    path: '/report/lead-report/referral/:id',
    name: 'Lead Report with Referral',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/report/lead_report/list')
},
// {
//     path: '/report/ip-view-details/:ip',
//     name: 'IP Views Details',
//     meta: {
//         authRequired: true,
//     },
//     component: () =>
//         import ('../views/np_pages/report/ip_tracker_details')
// },
{
    path: '/pos-vendors-list',
    name: 'POS Vendors',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/pos_vendors/pos_vendors_list')
},
{
    path: '/pos-vendor/:merchant_id',
    name: 'POS Vendor Personal',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/pos_vendors/pos_vendor_personal')
},
{
    path: '/portal-keywords-ads-list',
    name: 'Portal Keywords Ads List',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/portal_keywords_ads_list')
},
{
    path: '/portal-keywords-ads-details/:ads_id',
    name: 'Portal Keywords Ads Details',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/dashboard/portal_keywords_ads_details')
},
{
    path: '/order/details_mobile',
    name: 'Portal Keywords Ads Details',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/order_details.vue')
},
{
    path: '/website_setting',
    name: 'Website Setting',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/website_setting.vue')
},
{
    path: '/contact_n_social_media',
    name: 'Contact & Social Media',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/contact_n_social_media.vue')
},
{
    path: '/account_information',
    name: 'Account Information',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/account_info.vue')
},
{
    path: '/photo_all',
    name: 'All Photo',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/photo_all.vue')
},
{
    path: '/manage_album',
    name: 'All Photo',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/album_manage.vue')
},
{
    path: '/add_album',
    name: 'All Photo',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/album_add.vue')
},
{
    path: '/photo_edit/:id',
    name: 'editphoto',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/photo_edit.vue')
},
{
    path: '/quick_photo_edit',
    name: 'quickeditphotos',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/quick_photo_edit.vue')
},
{
    path: '/news_list',
    name: 'Manage News',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/news_all.vue')
},
{
    path: '/news_add',
    name: 'Add News',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/news_add.vue')
},
{
    path: '/news_edit/:id',
    name: 'News Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/news_edit.vue')
},
{
    path: '/news_group',
    name: 'News Edit',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/mobile_version/news_group.vue')
},
{
    path: '/version',
    name: 'Version',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/version')
},

/* -------------------------------------------------------------------------- */
/*                                   Justin page                              */
/* -------------------------------------------------------------------------- */
{
    path: '/tools/advanced-email-protection',
    name: 'Advanced Email Protection',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/tools/advanced_email_protection')
},

{
    path: '/np-ai-proposal',
    name: 'NEWPAGES Intelligent Proposal by AI',
    meta: {
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/np_ai_proposal/view.vue')
},

/* -------------------------------------------------------------------------- */
/*            put your router code above this 404                             */
/* -------------------------------------------------------------------------- */
{
    path: '/404',
    name: 'Error 404',
    meta: {
        guest: true,
        authRequired: true,
    },
    component: () =>
        import ('../views/np_pages/404-page')
},
{
    path: "/:pathMatch(.*)*",
    redirect: "/404",
}
]
